/* eslint-disable jsx-a11y/iframe-has-title */
import Banner from "../../sections/common/banner";
import { data } from "../../../globals/data/contact-us";
import { publicUrlFor } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

export default function ContactPage() {
  const contacts = [
    {
      name: "Mr. Sushil Singhal",
      role: "Managing Director (MD)",
      phone: ["+91 9896360460", "+91 78692 54696"],
      email: "infor@rrferro.com",
      image:
        "/assets/images/contact/sushil.png",
    },
    {
      name: "Mr. Neeraj Vashistha",
      role: "Manager",
      phone: ["+91 7400900460", "+91 9520462410"],
      email: "infor@rrferro.com",
      image:
        "/assets/images/contact/neeraj.jpg",
    },
  ];
  return (
    <>
      <Banner _data={data.banner} />

      <div className="section-full  p-t120 p-b120">
        <div className="section-content">
          <div style={{ marginBottom: "2rem" }} className="container">
            <div style={{ marginBottom: "3rem" }} className="contact-section">
              {/* <div className="wt-small-separator site-text-primary">
                <div>Our Mission</div>
              </div> */}
              <h2 className="contact-header">
                We welcome the opportunity to discuss how R R Ferro Alloys can
                meet your specific needs.
              </h2>
              <p className="contact-description">
                Please feel free to reach out to us for more information about
                our products and services.
              </p>
              <div className="contact-cards">
                {contacts.map((contact, index) => (
                  <div className="contact-card" key={index}>
                    <img
                      src={contact.image}
                      alt={contact.name}
                      className="contact-image"
                    />
                    <div className="contact-info">
                      <h3 className="contact-name">{contact.name}</h3>
                      <p className="contact-role">{contact.role}</p>
                      <p className="contact-phone">
                        <strong>Phone:</strong>
                        <br />
                        {contact.phone.map((number, i) => (
                          <span key={i} className="phone-number">
                            {number}
                          </span>
                        ))}
                      </p>
                      <p className="contact-email">
                        <strong>Email:</strong>{" "}
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="contact-one mb-5">
              {/* TITLE START*/}
              <div className="section-head left wt-small-separator-outer">
                <h2 className="wt-title m-b30">Send Us a Message</h2>
              </div>
              {/* TITLE END*/}
              {/* CONTACT FORM*/}
              <div className="contact-one-inner  site-bg-sky-blue-light">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="contact-form-outer">
                      <form
                        className="cons-contact-form"
                        method="post"
                        action="form-handler2.php"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group mb-3">
                              <input
                                name="username"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <input
                                name="email"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <input
                                name="phone"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <input
                                name="subject"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Subject"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <textarea
                                name="message"
                                className="form-control"
                                rows={3}
                                placeholder="Message"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="btn-half site-button"
                            >
                              <span>Submit Now</span>
                              <em />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div
                      className="contact-info  site-bg-dark bg-no-repeat bg-bottom-left"
                      style={{
                        backgroundImage: `url(${publicUrlFor(
                          "/assets/images/background/contact-bg.png"
                        )})`,
                      }}
                    >
                      {/* TITLE START*/}
                      <div className="section-head-small white mb-4">
                        <h3 className="wt-title">Let's get in touch</h3>
                      </div>
                      {/* TITLE END*/}
                      <div className="contact-info-section">
                        <div className="c-info-column">
                          <div className="c-info-icon fa fa-map-marker">
                            <span />
                          </div>
                          <p>
                            Plot No.- 65, 66/A And 66/C, INDUSTRIAL AREA,
                            RAJGAMAR ROAD, Korba, Chhattisgarh, 495677
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon fa fa-mobile-phone custome-size">
                            <span />
                          </div>
                          <p>
                            <a href="tel:+216-761-8331">+91 74009 00460</a>
                          </p>
                          <p>
                            <a href="tel:+216-761-8331">+91 95204 62410</a>
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon fa fa-envelope-o">
                            <span />
                          </div>
                          <p>info@rrferro.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gmap-outline ">
              <div className="google-map">
                <div style={{ width: "100%" }}>
                  <iframe
                    height={460}
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3689.8446413476763!2d82.74588457529424!3d22.359494279644046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDIxJzM0LjIiTiA4MsKwNDQnNTQuNSJF!5e0!3m2!1sen!2sin!4v1734250870952!5m2!1sen!2sin"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
