import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";

export default function Footer1() {
  return (
    <>
      <footer className="footer-dark">
        {/* NEWS LETTER SECTION START */}
        {/* <div className="ftr-nw-ltr site-bg-white">
                
                    <div className="ftr-nw-ltr-inner site-bg-primary">
                        <div className="container">
                            <div className="ftr-nw-img">
                                <img src={publicUrlFor("/assets/images/news-l-bg.png")} alt="#" />
                            </div>
                            <div className="ftr-nw-content">
                                <div className="ftr-nw-title">Subscribe for offers and news</div>
                                <div className="ftr-nw-form">
                                    <form>
                                        <input name="news-letter" className="form-control" placeholder="Enter Your Email" type="text" />
                                        <button className="ftr-nw-subcribe-btn">Subscribe Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div> */}
        {/* NEWS LETTER SECTION END */}

        <div
          className="ftr-bg"
          style={{
            backgroundImage: `url(${publicUrlFor("/assets/images/f-bg.jpg")})`,
          }}
        >
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/index">
                        <img src={publicUrlFor("/")} alt="#" />
                      </NavLink>
                    </div>
                    <p>
                      Building Global Steel Strength Through Advanced Ferro
                      Technology and Innovation
                    </p>
                    <ul className="social-icons">
                      <li>
                        <a
                          href="https://www.facebook.com/"
                          className="fa fa-facebook"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.twitter.com/"
                          className="fa fa-twitter"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/"
                          className="fa fa-instagram"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/"
                          className="fa fa-youtube-play"
                        >
                          {" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/about-us">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/product">Products</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Clients</h3>
                    <ul>
                      <li>
                        <NavLink to="#">Bokaro steel plant</NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Durgapur steel plant </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          Bharat Heavy Electrical Limited (BHEL)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Visakhapatnam steel plant </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Rastriya Ispat Nigam Limited </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">Jindal Ispat Nigam limited </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    {/* ISO Certified Section */}
                    <div className="iso-certified">
                        <img   style={{width:"80px", marginBottom:"15px"}} src="/assets/images/icons/iso.png"/>
                      <p>
                       ISO Certified Company
                      </p>
                      <p className="iso-num">ISO 9001:2015</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}

          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-info">
                <div className="footer-copy-right">
                  <span className="copyrights-text">
                    Copyright © 2024 by Ferro Alloys All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
