import WhoWeAre from "../../app/sections/home/index/whoWeAre";
import { global } from "../constants";

export const data = {
  WhoWeAre: {
    title: "Who We Are",
    subtitle: "Pioneering Ferro Excellence",
    description:
      "Since 1996, R R Ferro Alloys has emerged as a leading ISO 9001:2015 certified manufacturer, operating from our advanced facility in Korba, Chhattisgarh. We've grown through innovation and adaptability, becoming a pathbreaker in Ferro Alloy production.",
    branches: "295+",
    since: "1996",
    specifications: [
      "Pioneers in high-quality Ferro Vanadium production for over three decades.",
      "State-of-the-art manufacturing facility with modern technologies.",
      "Expert in Ferro Vanadium, Vanadium Pentoxide, and Ammonium Metavanadate.",
      "Committed to customer satisfaction through quality service and flexible operations.",
      "Driven by principles of good faith management and mutual growth.",
    ],
  },
  whatwedo: {
    title: "What we do!",
    subtitle: "Powering Steel Innovation",
    description:
      "At R R Ferro Alloys, we are specialists in high-quality ferroalloy production. Our core manufacturing focuses on:",
    works: [
      {
        icon: global.icons2 + "pic1.png",
        serial: "01",
        title: "Ferro Vanadium",
        description:
          "Premium grade alloy with minimum 50% Vanadium content, essential for manufacturing high-strength steel and specialty alloys.",
      },
      {
        icon: global.icons2 + "pic2.png",
        serial: "02",
        title: "Vanadium Pentoxide",
        description:
          "High-purity Vanadium Pentoxide with 98% minimum concentration, crucial for catalysts and advanced material production.",
      },
      {
        icon: global.icons2 + "pic3.png",
        serial: "03",
        title: "Ammonium Meta Vanadate",
        description:
          "Superior Ammonium Metavanadate with 70% V2O5 content, vital for chemical processing and industrial applications.",
      },
    ],
  },
  //   clients: [
  //     global.logo + "w1.png",
  //     global.logo + "w2.png",
  //     global.logo + "w3.png",
  //     global.logo + "w4.png",
  //     global.logo + "w5.png",
  //     global.logo + "w1.png",
  //     global.logo + "w2.png",
  //     global.logo + "w3.png",
  //     global.logo + "w4.png",
  //     global.logo + "w5.png"
  // ],
  clientSection: {
    title: "Get To Know Us!",
    subtitle:
      "Strengthening Industries Worldwide Through Strategic Partnership",
    description:
      "Strengthening Industries Worldwide Through Strategic Partnership",
    clients: [
      "/assets/images/clients/bhel.png",

      "/assets/images/clients/sail-bhilai.png",

      "/assets/images/clients/sail-Durgapur.png",

      "/assets/images/clients/VIZAG-STEEL.png",
    ],
  },

  whychooseus: {
    background: global.back + "bg-1.jpg",
    title: "Why Choose Us",
    subtitle: "We Are Professional Logistics & cargo Agency",
    description: {
      short:
        "Sed ut perspiciatis unde omnis iste natus error volup tatem accusantium dolorem que laudantium, totam rem aperiam, eaque ipsa illo inventore.",
      long: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolorem que laudantium, totam rem aperiam, que ipsa quae ab illo inventore veritatis quasi architecto beatae itae dicta sunt explicabo. Nemo enim ipsam voluptate voluptas sitr atur aut odit aut fugit, sed quia consequuntur magni dolores is eos qui ratione voluptatem sequi nesciunt.",
    },
    pic1: global.whyChoose + "pic1.jpg",
    pic2: global.whyChoose + "pic2.jpg",
    help: {
      icon: global.whyChoose + "qt-icon.png",
      number: "(2 900 234 4241)",
    },
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Request A Quote",
    subtitle: "Booking For Product Transformation",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png",
    },
  },

  estimation: {
    background: global.back + "bg-2.jpg",
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized expertise",
        subtitle:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service",
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        subtitle:
          "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind...",
      },
      {
        serial: "03",
        title: "Tracking made easy",
        subtitle:
          "A tracking number for the entire process. so that you can find the exact position. it will help you",
      },
    ],
  },

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/w1.png",
      global.logo + "/w2.png",
      global.logo + "/w3.png",
      global.logo + "/w4.png",
      global.logo + "/w5.png",
      global.logo + "/w6.png",
    ],
    partners: [
      {
        pic: global.testim + "pic1.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer",
      },
      {
        pic: global.testim + "pic2.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer",
      },
      {
        pic: global.testim + "pic3.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer",
      },
      {
        pic: global.testim + "pic4.jpg",
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer",
      },
    ],
  },
  testimonials1: {
    title: "Testimonials",
    subtitle: "Real Businesses. Real Growth.",
    quotes: [
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer",
        image: global.testim + "pic1.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer",
        image: global.testim + "pic2.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer",
        image: global.testim + "pic3.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer",
        image: global.testim + "pic4.jpg",
      },
    ],
  },

  services: {
    title: "Our Values",
    subtitle: "Trusted For Our Services",
    description:
      "Our products are essential in the production of steel and other alloys, providing necessary strength, durability, and resistance to wear and corrosion. We employ state-of-the-art technology and adhere to stringent quality control measures to ensure our products meet the highest standards.",
    services: [
      {
        icon: global.icons + "1.png",
        serial: "01",
        title: "Quality",
        description:
          "We prioritize the quality of our products and services, ensuring they meet and exceed industry standards.",
      },
      {
        icon: global.icons + "2.png",
        serial: "02",
        title: "Innovation",
        description:
          "We invest in research and development to stay ahead of market trends and continuously improve our offerings.",
      },
      {
        icon: global.icons + "3.png",
        serial: "03",
        title: "Sustainability",
        description:
          "We are committed to environmentally responsible practices, minimizing our ecological footprint while maximizing efficiency. ",
      },
      {
        icon: global.icons + "4.png",
        serial: "04",
        title: "Customer Focus",
        description:
          "We build lasting relationships with our clients by understanding their needs and delivering customized solutions.",
      },
    ],
  },

  approach: {
    title: "Company Approch",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "35",
    specifications: [
      {
        icon: global.icons + "c-pic1.png",
        count: 195,
        title: "Professional Staff",
      },
      {
        icon: global.icons + "c-pic2.png",
        count: 250,
        title: "On Time Delievery",
      },
      {
        icon: global.icons + "c-pic3.png",
        count: 350,
        title: "Technology and Media",
      },
    ],
  },
  services1: {
    title: "",
    subtitle: "",
    description: "",
    services: [
      {
        icon: global.icons + "pic1.png",
        serial: "01.",
        title: "Built for Growth",
        description:
          "Ferro Alloys empowers labour-hire agencies to grow their business by simplifying the candidate experience and accelerating the onboarding process.",
      },
      {
        icon: global.icons + "pic2.png",
        serial: "02.",
        title: "Leave your competition in the dust",
        description:
          "Empowering various Australian industries, our staffing and labor hire clients utilize our robust staffing software to attract and retain top-tier workers",
      },
      {
        icon: global.icons + "pic3.png",
        serial: "03.",
        title: "Attract more staff to your business",
        description:
          "Ferro Alloys is recruiting and scheduling more casual and contract workers than any other on-hire software in Australia. ",
      },
      {
        icon: global.icons + "pic4.png",
        serial: "04.",
        title: "Win the war for talent",
        description:
          "Make the switch for your business and see why thousands of Australian workers prefer Ferro Alloys.",
      },
    ],
  },
  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title:
          "At the end of the day, going forward, a new normal that has evolved from.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title:
          "How all this mistaken idea denouncing pleasure and praising pain was.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
    ],
  },

  projects: {
    title: "Projects",
    subtitle: "Featured Projects",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "2.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge",
      },
      {
        image: global.projects + "3.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge",
      },
    ],
  },
};
