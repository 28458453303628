import React from "react";

const CustomTable = ({ headers, data, title, serial, image }) => {
  return (
    <div
      style={{ marginBottom: "4rem" }}
      className="section-full  site-bg-white  tw-estimation-area3"
    >
      <div className="container">
        <h3 className="table-title">
          {serial}. {title}
        </h3>
        {/* Image Section */}
        {image && (
          <div className="table-image">
            <img src={image} alt={title} className="table-image" />
          </div>
        )}

        <div className="custom-table">
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
