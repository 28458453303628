import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionAchivements1 from "../../sections/home/index2/section-achivements1";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import SectionCompanyApproach2 from "../../sections/home/index3/section-company-approach2";
import { data } from "../../../globals/data/product";
import OurMission from "../../sections/home/index/mission";
import OurTeam from "../../sections/home/index/ourTeam";
import CustomTable from "../../sections/home/index/customTable";
import WhyChooseUsAbout from "../../sections/home/index/why-choose-us-about";

export default function ProductPage() {
  const headers1 = ["Product", "V", "Mo.", "Al.", "C.", "Si.", "P.", "S."];

  // Define rows (data)
  const data1 = [
    ["FeV 55 (v-50%)", "50", "-", "1.50", "0.10", "1.50", "0.10", "0.10"],
    ["FeV 55 (LSP)", "50", "-", "1.50", "0.10", "1.50", "0.03", "0.03"],
    ["FeV 55 (V-60%)", "55", "-", "1.00", "0.10", "1.00", "0.05", "0.05"],
    ["FeV 80 (V-80%)", "75", "-", "3.00", "0.10", "1.50", "0.03", "0.03"],
  ];

  const headers2 = ["Product", "V2O5", "Na2O", "K2O", "MgO", "CaO", "FeO"];

  // Define rows (data)
  const data2 = [
    [
      "Technical (Unfused) Powder",
      "99.50",
      "0.23",
      "0.02",
      "0.01",
      "0.05",
      "0.05",
    ],
  ];

  const headers3 = ["V as NH4VO3", "Fe", "S", "Na2O", "K2O"];

  // Define rows (data)
  const data3 = [["99%", "0.05", "0.02", "0.22", "0.02"]];
  return (
    <>
      <Banner _data={data.banner} />

      <div className="container home-client2-outer p-t120">
        {/* <!-- TITLE START--> */}
        <div className="section-head center wt-small-separator-outer">
          <h2 className="wt-title">
            Strengthening Tomorrow with Vanadium Innovation
          </h2>
          <p style={{ paddingTop: "1rem" }}>
            At R R Ferro Alloys, we specialize in the production of high-quality
            vanadium compounds essential for various industrial applications.
          </p>
        </div>
      </div>

      <CustomTable
        headers={headers1}
        data={data1}
        serial={1}
        title={"Ferro Vanadium"}
        image={"/assets/images/raw/m1.jpg"}
      />
      <CustomTable
        headers={headers2}
        data={data2}
        serial={2}
        title={"Vanadium Pentoxide"}
        image={"/assets/images/raw/m2.jpg"}
      />
      <CustomTable
        headers={headers3}
        data={data3}
        serial={3}
        title={"Ammonium Metavanadate"}
        image={"/assets/images/raw/m3.jpg"}
      />
      {/* <SectionAchivements1 _data={data.achivements} /> */}
      {/* <SectionTestimonials1 _data={data.testimonials} /> */}
      {/* <SectionBlogs1 _data={data.blogs} /> */}
      {/* <SectionCompanyApproch1 _data={data.approach} /> */}
    </>
  );
}
